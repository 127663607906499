<template>
  <div>
    <CSBreadcrumb />
    <div class="process">
      <div class="process_item">
        <div class="process_title">房间信息</div>
        <div class="process_info">
          <span class="item_title"> 房间号 </span>
          <span class="item_info">
            {{
              DecorationDetail.buildingName +
              DecorationDetail.floor +
              "层" +
              DecorationDetail.roomNo
            }}
          </span>
          <span class="item_title"> 房间面积 </span>
          <span class="item_info"> {{ DecorationDetail.space }}㎡ </span>
        </div>
      </div>
      <hr />
      <div class="process_item">
        <div class="process_title">装修公司信息</div>
        <div class="process_info">
          <span class="item_title"> 装修公司 </span>
          <span class="item_info">
            {{ DecorationDetail.decorationCompany }}
          </span>
          <span class="item_title"> 装修负责人 </span>
          <span class="item_info">
            {{ DecorationDetail.decorationName }}
          </span>
          <span class="item_title"> 负责人手机号 </span>
          <span class="item_info">
            {{ DecorationDetail.decorationPhone }}
          </span>
        </div>
      </div>
      <hr />
      <div class="process_item">
        <div class="process_title">申报人信息</div>
        <div class="process_info">
          <span class="item_title"> 申报人身份 </span>
          <span class="item_info">
            {{ DecorationDetail.type == 1 ? "业主" : "租客" }}
          </span>
          <span class="item_title"> 申报人 </span>
          <span class="item_info">
            {{ DecorationDetail.declarantName }}
          </span>
          <span class="item_title"> 手机号 </span>
          <span class="item_info">
            {{ DecorationDetail.declarantPhone }}
          </span>
          <span class="item_title"> 身份证号 </span>
          <span class="item_info">
            {{ DecorationDetail.declarantIdCard }}
          </span>
        </div>
      </div>
      <hr />
      <div class="process_item">
        <div class="process_title">装修详情</div>
        <div class="process_info">
          <div style="overflow: hidden;float: left">
            <span class="item_title"> 装修期限 </span>
            <span class="item_info">
            {{
              DecorationDetail.decorationStart +
              " 至 " +
              DecorationDetail.decorationEnd
            }}
          </span>
          </div>
          <div style="overflow: hidden">
            <span class="item_title" style="display: inline-block">装修项目</span>
              <span class="item_info">
                <span :key="index" v-for="(a, index) in DecorationDetail.items">
                    {{ a }}
               </span>
             </span>
          </div>
        </div>
      </div>
    </div>

    <!--照片-->
    <div class="process_certificate">
      <div class="items">
        <div class="certificate_title">不动产权证书</div>
        <div class="certificate_image">
          <template v-if="DecorationDetail.realEstateUrl">
            <div
              :key="index"
              v-for="(realEstateItem, index) in DecorationDetail.realEstateUrl"
            >
              <img
                :src="realEstateItem"
                alt=""
                @click="imgBtn(realEstateItem)"
              />
            </div>
          </template>
          <template v-else>
            <div>-</div>
          </template>
        </div>
      </div>
      <div style="clear: both"></div>
      <div class="items">
        <div class="certificate_title">业主同意装修证明</div>
        <div class="certificate_image">
          <template v-if="DecorationDetail.decorationProveUrl">
            <div
              :key="index"
              v-for="(
                realEstateItem, index
              ) in DecorationDetail.decorationProveUrl"
            >
              <img
                :src="realEstateItem"
                alt=""
                @click="imgBtn(realEstateItem)"
              />
            </div>
          </template>
          <template v-else>
            <div>-</div>
          </template>
        </div>
      </div>
      <div style="clear: both"></div>
      <div class="items">
        <div class="certificate_title">租赁合同照片</div>
        <div class="certificate_image">
          <template v-if="DecorationDetail.contractUrl">
            <div
              :key="index"
              v-for="(realEstateItem, index) in DecorationDetail.contractUrl"
            >
              <img
                :src="realEstateItem"
                alt=""
                @click="imgBtn(realEstateItem)"
              />
            </div>
          </template>
          <template v-else>
            <div>-</div>
          </template>
        </div>
      </div>
      <div style="clear: both"></div>
      <div class="items">
        <div class="certificate_title">申报人身份证照片</div>
        <div class="certificate_image">
          <template v-if="DecorationDetail.declarantIdCardUrl">
            <div
              :key="index"
              v-for="(
                realEstateItem, index
              ) in DecorationDetail.declarantIdCardUrl"
            >
              <img
                :src="realEstateItem"
                alt=""
                @click="imgBtn(realEstateItem)"
              />
            </div>
          </template>
          <template v-else>
            <div>-</div>
          </template>
        </div>
      </div>
      <div style="clear: both"></div>
      <div class="items">
        <div class="certificate_title">业主身份证照片</div>
        <div class="certificate_image">
          <template v-if="DecorationDetail.ownerIdCardUrl">
            <div
              :key="index"
              v-for="(realEstateItem, index) in DecorationDetail.ownerIdCardUrl"
            >
              <img
                :src="realEstateItem"
                alt=""
                @click="imgBtn(realEstateItem)"
              />
            </div>
          </template>
          <template v-else>
            <div>-</div>
          </template>
        </div>
      </div>
      <div style="clear: both"></div>
      <div class="items">
        <div class="certificate_title">装修负责人身份证照片</div>
        <div class="certificate_image">
          <template v-if="DecorationDetail.decorationIdCardUrl">
            <div
            :key="index"
            v-for="(
              realEstateItem, index
            ) in DecorationDetail.decorationIdCardUrl"
          >
            <img :src="realEstateItem" alt="" @click="imgBtn(realEstateItem)" />
          </div>
          </template>
          <template v-else>
            <div>-</div>
          </template>
        </div>
      </div>
      <div style="clear: both"></div>
      <div class="items">
        <div class="certificate_title">装修公司营业执照</div>
        <div class="certificate_image">
          <template v-if="DecorationDetail.decorationBusinessLicenseUrl">
            <div
            :key="index"
            v-for="(
              realEstateItem, index
            ) in DecorationDetail.decorationBusinessLicenseUrl"
          >
            <img :src="realEstateItem" alt="" @click="imgBtn(realEstateItem)" />
          </div>
          </template>
          <template v-else>
            <div>-</div>
          </template>
        </div>
      </div>
      <div style="clear: both"></div>
      <div class="items">
        <div class="certificate_title">装修承诺书照片</div>
        <div class="certificate_image">
          <template v-if="DecorationDetail.decorationCommitmentUrl">
            <div
            :key="index"
            v-for="(
              realEstateItem, index
            ) in DecorationDetail.decorationCommitmentUrl"
          >
            <img :src="realEstateItem" alt="" @click="imgBtn(realEstateItem)" />
          </div>
          </template>
          <template v-else>
            <div>-</div>
          </template>

        </div>
      </div>
      <div style="clear: both"></div>
      <div class="items">
        <div class="certificate_title">装饰装修方案图纸</div>
        <div class="certificate_image">
          <template v-if="DecorationDetail.decorationDrawingUrl">
            <div
            :key="index"
            v-for="(
              realEstateItem, index
            ) in DecorationDetail.decorationDrawingUrl"
          >
            <img :src="realEstateItem" alt="" @click="imgBtn(realEstateItem)" />
          </div>
          </template>
          <template v-else>
            <div>-</div>
          </template>

        </div>
      </div>
      <div style="clear: both"></div>
      <div class="items">
        <div class="certificate_title">建筑工程施工许可证</div>
        <div
          class="certificate_image"

        >
        <template v-if="DecorationDetail.constructionWordPermitUrl">
           <div
            :key="index"
            v-for="(
              realEstateItem, index
            ) in DecorationDetail.constructionWordPermitUrl"
          >
            <img :src="realEstateItem" alt="" @click="imgBtn(realEstateItem)" />
          </div>
        </template>
         <template v-else>
           <div>-</div>
         </template>
        </div>

      </div>
      <div style="clear: both" class="clarBo"></div>
    </div>

    <!--同意拒绝-->
    <div
      class="process_approve"
      v-if="!DecorationDetail.state"
      style="background: rgba(255, 255, 255, 1)"
    >
      <div class="approve_info">
        <div style="float: left">
          <span>审批意见</span>
        </div>
        <div style="float: left">
          <CSRadio
            v-model="saveDetail"
            :items="{ 1: '通过', 2: '拒绝' }"
            style="margin-left: 40px"
            width='20px'
            innerWidth='15px'
          />
        </div>
        <div style="float: left">
          <span v-if="saveDetail === 2" style="margin-left: 40px">
            原因
            <input
              type="text"
              style="
                margin-left: 30px;
                border-radius: 4px;
                border: 1px solid #979797;
                height: 40px;
                padding-left: 10px;
              "
              placeholder="限60字"
              v-model="refusalCause"
            />
          </span>
        </div>
      </div>
      <div class="approve_button">
        <button
          class="btn btn-primary btn-sm"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          style="
            display: block;
            margin: 0 auto;
            width: 200px;
            height: 40px;
            background: #00b694;
            box-shadow: 0px 5px 10px rgba(0, 182, 148, 0.3);
            opacity: 1;
            border-radius: 4px;
          "
          @click="submitApproval"
        >
          提交审批结果
        </button>
      </div>
    </div>
    <CheckRotationMap
      v-if="lookJurisdictionVisible"
      :lookJurisdictionVisible="lookJurisdictionVisible"
      :showUrl="showUrl"
      :photoList="photoList"
      @closeBtn="closeBtn"
    ></CheckRotationMap>
  </div>
</template>

<script>
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import { queryDecorateDetail, changeResults } from "@/requestUrl";
import CSRadio from "@/components/common/CSRadio";
import CheckRotationMap from "./CheckRotationMap";
export default {
  name: "DecorationInfo",
  components: {
    CSBreadcrumb,
    CSRadio,
    CheckRotationMap,
  },
  props: {
    id: Number,
  },
  data() {
    return {
      DecorationDetail: {},
      saveDetail: "",
      refusalCause: "",
      lookJurisdictionVisible: false,
      photoList: [],
      showUrl: "",
    };
  },
  created() {
    this.queryDecorationDetails();
  },
  mounted() {},
  methods: {
    closeBtn(e) {
      this.lookJurisdictionVisible = false;
    },
    //添加点击事件
    imgBtn(url) {
      this.showUrl = url;
      this.lookJurisdictionVisible = true;
    },
    //解析图片
    parsePicture() {
      this.photoList = [
        ...this.mapArr(
          this.DecorationDetail.realEstateUrl,
          "不动产权证书",
          "realEstateUrl"
        ), //不动产权证书 字符串
        ...this.mapArr(
          this.DecorationDetail.decorationProveUrl,
          "业主同意装修证明",
          "decorationProveUrl"
        ), //业主同意装修证明
        ...this.mapArr(
          this.DecorationDetail.contractUrl,
          "租赁合同",
          "contractUrl"
        ),
        ...this.mapArr(
          this.DecorationDetail.declarantIdCardUrl,
          "申报人身份证照片",
          "declarantIdCardUrl"
        ),
        ...this.mapArr(
          this.DecorationDetail.ownerIdCardUrl,
          "业主身份证照片",
          "ownerIdCardUrl"
        ),
        ...this.mapArr(
          this.DecorationDetail.decorationIdCardUrl,
          "装修负责人身份证",
          "decorationIdCardUrl"
        ),
        ...this.mapArr(
          this.DecorationDetail.decorationBusinessLicenseUrl,
          "装修公司营业执照",
          "decorationBusinessLicenseUrl"
        ),
        ...this.mapArr(
          this.DecorationDetail.decorationCommitmentUrl,
          "装修承诺书",
          "decorationCommitmentUrl"
        ),
        ...this.mapArr(
          this.DecorationDetail.decorationDrawingUrl,
          "装修方案图纸",
          "decorationDrawingUrl"
        ),
        ...this.mapArr(
          this.DecorationDetail.constructionWordPermitUrl,
          "建筑工程施工许可证",
          "constructionWordPermitUrl"
        ),
      ];
      console.log(this.photoList);
    },
    mapArr(arr, tName, Tag) {
      if (arr && arr.length > 0) {
        return arr.map((item) => {
          return {
            imgUrl: item,
            name: tName,
            tag: Tag,
          };
        });
      }
      return [];
    },
    submitApproval() {
      const params = {
        id: this.id,
        state: this.saveDetail,
        refusalCause: this.refusalCause,
      };
      this.$fly.post(changeResults, params).then((res) => {
        if (res.code !== 0) {
          return;
        } else {
          this.$vc.toast("提交成功");
          this.$router.push({
            name: "decoration",
          });
        }
      });
    },
    queryDecorationDetails() {
      this.$fly.get(queryDecorateDetail, { id: this.id }).then((res) => {
        if (res.code !== 0) {
          return;
        } else {
          this.DecorationDetail = res.data;
          this.urlToArray();
          this.parsePicture();
        }
      });
    },
    urlToArray() {
      let detail = this.DecorationDetail;
      if (detail.realEstateUrl) {
        this.DecorationDetail.realEstateUrl = JSON.parse(
          this.DecorationDetail.realEstateUrl
        );
      }
      if (detail.decorationProveUrl) {
        this.DecorationDetail.decorationProveUrl = JSON.parse(
          this.DecorationDetail.decorationProveUrl
        );
      }
      if (detail.contractUrl) {
        this.DecorationDetail.contractUrl = JSON.parse(
          this.DecorationDetail.contractUrl
        );
      }
      if (detail.declarantIdCardUrl) {
        this.DecorationDetail.declarantIdCardUrl = JSON.parse(
          this.DecorationDetail.declarantIdCardUrl
        );
      }
      if (detail.ownerIdCardUrl) {
        this.DecorationDetail.ownerIdCardUrl = JSON.parse(
          this.DecorationDetail.ownerIdCardUrl
        );
      }
      if (detail.decorationIdCardUrl) {
        this.DecorationDetail.decorationIdCardUrl = JSON.parse(
          this.DecorationDetail.decorationIdCardUrl
        );
      }
      if (detail.decorationBusinessLicenseUrl) {
        this.DecorationDetail.decorationBusinessLicenseUrl = JSON.parse(
          this.DecorationDetail.decorationBusinessLicenseUrl
        );
      }
      if (detail.decorationCommitmentUrl) {
        this.DecorationDetail.decorationCommitmentUrl = JSON.parse(
          this.DecorationDetail.decorationCommitmentUrl
        );
      }
      if (detail.decorationDrawingUrl) {
        this.DecorationDetail.decorationDrawingUrl = JSON.parse(
          this.DecorationDetail.decorationDrawingUrl
        );
      }
      if (detail.constructionWordPermitUrl) {
        this.DecorationDetail.constructionWordPermitUrl = JSON.parse(
          this.DecorationDetail.constructionWordPermitUrl
        );
      }
    },
  },
};
</script>

<style scoped>
.process {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

hr {
  margin: 0em 30px;
}


.process > .process_item {
  /*height: 111px;*/
}
.process_title::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 24px;
  background: rgba(0, 182, 148, 1);
  left: -15px;
  top: 25px;
  opacity: 1;
}
.process .process_title {
  margin-left: 48px;
  padding-top: 20px;
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  color: #000000;
  opacity: 1;
  position: relative;
}
.process .process_info {
  margin-left: 30px;
  padding-top: 10px;
  margin-bottom: 20px;
}

.item_title {
  /*width: 60px;*/
  height: 28px;
  font-size: 20px;
  font-weight: 550;
  line-height: 28px;
  color: #000000;
  opacity: 1;
}

.item_info {
  margin-left: 10px;
  margin-right: 60px;
  width: 132px;
  height: 28px;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: #000000;
  opacity: 1;
}

.process_certificate {
  margin-top: 20px;
  height: auto;
  background: rgba(255, 255, 255, 1);
}

.process_certificate div > div {
  font-size: 24px;
  font-weight: 400;
  line-height: 33px;
  color: #000000;
  opacity: 1;
  float: left;
}

.process_certificate > div {
  padding-top: 20px;
}

.process_certificate img {
  width: 80px;
  height: 80px;
}

.certificate_title {
  width: 270px;
  text-align: right;
}

.certificate_title {
  width: 270px;
  text-align: right;
  flex: 0 0 auto;
}

.certificate_image {
  margin-left: 40px;
}

.certificate_image div {
  margin-left: 20px;
  margin-bottom: 20px;
}
.certificate_image {
  margin-left: 40px;
}

.certificate_image div {
  margin-left: 20px;
}

.process_approve {
  margin-top: 23px;
  height: 170px;
  background: rgba(255, 255, 255, 0.39);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  opacity: 1;

  font-size: 24px;
  font-weight: 400;
  line-height: 33px;
  color: #000000;
}

.approve_info {
  margin-left: 30px;
  padding-top: 23px;
  /*overflow:hidden;*/
}

.process_approve > div > span:nth-child(1) {
  margin-top: 23px;
  margin-left: 30px;
}
.items {
  display: flex;
}
.approve_button {
  margin-top: 87px;
}
.approve_button > button {
  height: 33px;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
  opacity: 1;
}
</style>
