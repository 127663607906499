<template>
  <div class="check-wrapper">
    <CSDialog
      :dialogVisible="lookJurisdictionVisible"
      dialogWidth="920px"
      dialogTitle="查看图片"
      dialog-header-class="alert-bg"
      dialogCancelBtnText="关闭"
      :dialogShowConfirmBtn="false"
      @onClose="closeBtn"
    >
      <div
        slot="dialog-content"
        style="padding: 30px 30px 30px 0"
        class="check-container"
      >
        <div class="topB">
          <svg class="icon" aria-hidden="true" style="margin: 0 15px 0 20px">
            <use xlink:href="#icon-menua-zu92"></use></svg
          >图片放大后可按住鼠标左键拖动查看。
          <span class="span">{{ photoList[imgIndex].name }}</span>
        </div>
        <ul>
          <li ref="imgContainer" @mousedown="dragImage">
            <img :src="photoList[imgIndex].imgUrl" ref="imgShow" />
          </li>
        </ul>
        <div class="yBtn">
          <div class="btn-left el-icon-arrow-left" @click="switchImage(-1)" ></div>
          <div class="btn-right el-icon-arrow-right" @click="switchImage(1)"></div>
        </div>
        <div class="sBtn">
          <div class="enlarge" @click="sizeBtn(0.1)">
            <svg class="icon">
              <use xlink:href="#icon-menufangda"></use>
            </svg>
          </div>
          <div class="narrow" @click="sizeBtn(-0.1)">
            <svg class="icon">
              <use xlink:href="#icon-menusuoxiao1"></use>
            </svg>
          </div>
        </div>
      </div>
    </CSDialog>
  </div>
</template>

<script>
import CSDialog from "@/components/common/CSDialog";
export default {
  name: "CheckRotationMap",
  components: {
    CSDialog,
  },
  props: {
    lookJurisdictionVisible: {
      type: Boolean,
      default: false,
    },
    showUrl: {
      type: String,
      default: false,
    },
    photoList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      imgContainer: null,
      imgIndex: 0,
      magnification: 1,
    };
  },
  created() {
    this.checkIndex();
  },
  mounted() {
    this.imgContainer = this.$refs.imgContainer;
    this.setImageSize();
    this.imgContainer.style.left = `calc(50% - ${
      this.$refs.imgShow.clientWidth / 2
    }px)`;
  },
  methods: {
    closeBtn() {
      this.$emit("closeBtn", false);
    },
    checkIndex() {
      for (var i = 0; i < this.photoList.length; i++) {
        if (this.photoList[i].imgUrl == this.showUrl) {
          this.imgIndex = i;
        }
      }
    },
    //图片切换
    switchImage(index) {
      this.$nextTick(() => {
        this.setImageSize();
      });
      this.imgContainer.style.left = `calc(50% - ${
        this.$refs.imgShow.clientWidth / 2
      }px)`;
      this.imgContainer.style.top = 0;
      this.imgContainer.style.transform = `scale(${1})`;
      let imgIndex = this.imgIndex + index;
      if (imgIndex > this.photoList.length - 1) {
        imgIndex = 0;
      }
      if (imgIndex < 0) {
        imgIndex = this.photoList.length - 1;
      }
      this.imgIndex = imgIndex;
      this.$nextTick(() => {
        this.setImageSize();
        this.imgContainer.style.left = `calc(50% - ${
          this.$refs.imgShow.clientWidth / 2
        }px)`;
      });
    },
    //调整图片大小
    sizeBtn(index) {
      this.magnification += index;
      if (this.magnification <= 0.1) {
        this.magnification = 0.1;
      }
      this.imgContainer.style.transform = `scale(${this.magnification})`;
    },
    //调整图片大小
    setImageSize() {
      let adjustment = this.isSizeImage();
      //宽小于高
      if (adjustment < 0) {
        this.$refs.imgShow.style.height = "100%";
        this.$refs.imgShow.style.width = "auto";
      } else {
        this.$refs.imgShow.style.width = "100%";
        this.$refs.imgShow.style.height = "auto";
      }
    },
    //判断图片长款大小
    isSizeImage() {
      let width = this.$refs.imgShow.clientWidth;
      let height = this.$refs.imgShow.clientHeight;
      return width - height;
    },
    //左右拖动
    dragImage(e) {
      e.preventDefault();
      //算出鼠标相对元素的位置
      let disX = e.clientX - this.imgContainer.offsetLeft;
      let disY = e.clientY - this.imgContainer.offsetTop;
      document.onmousemove = (e) => {
        //鼠标按下并移动的事件
        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = e.clientX - disX;
        let top = e.clientY - disY;
        //移动当前元素
        this.imgContainer.style.left = left + "px";
        this.imgContainer.style.top = top + "px";
      };
      this.imgContainer.onmouseup = (e) => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
      document.onmouseup = (e) => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
  },
};
</script>

<style>
.topB {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 50px;
  color: #ffffff;
  opacity: 1;
  width: 860px;
  height: 50px;
  background: rgba(0, 0, 0, 0.39);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 10;
  margin-left: 30px;
}
ul,
li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.span {
  float: right;
  margin-right: 20px;
}
.check-container:hover .btn-left {
  left: 50px;
  opacity: 1;
}
.check-container:hover .btn-right {
  right: -875px;
  opacity: 1;
}
.check-container {
  width: 880px;
  box-sizing: border-box;
  position: relative;
}
.check-container ul {
  width: 860px;
  overflow: hidden;
  margin-left: 30px;
  height: 500px;
  position: relative;
}
.check-container ul li {
  height: 100%;
  width: 100%;
  position: absolute;
}
.check-container ul li img {
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.sBtn {
  position: absolute;
  right: 20px;
  bottom: 50px;
}
.sBtn div {
  width: 40px;
  height: 40px;
  background-color: #00b694;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 10px;
  line-height: 40px;
  font-size: 24px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  font-weight: 700;
  cursor: pointer;
}
.yBtn {
  position: absolute;
  top: calc(50% - 18px);
}
.yBtn div {
  width: 36px;
  height: 36px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  position: absolute;
  font-size: 12px;
  color: #ffff;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  line-height: 36px;
  transition: all 0.5s;
}
.btn-left {
  left: 0px;
  opacity: 0;
}
.btn-right {
  right: -925px;
  opacity: 0;
}
</style>